<template>
	<div>
		<common-table
				:itemScope="['status']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:leftConfig="leftConfig"
				:rightConfig="rightConfig"
				:listDeploy="listDeploy"
				pageTitle="二级资源"
				pageKey="code"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
				@otherRightOperate="otherRightOperate"
        @beforeOpenAddDialog="beforeOpenAddDialog"
		>
			<template v-slot:status="{ item }">
				<w-switches
						inset
						label=""
						color="primary"
						@change="updateStatus(item.sqlid)"
						v-model="item.status" hide-details dense/>
			</template>
		</common-table>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import resource from './resource.js';
import api from '@/api/common-data.js';

export default {
	mixins: [commonCurd, resource],
	data() {
		return {
			api,
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			pageParams: ['HOTELKIT', 'RMKIT', 'MGKIT', 'MEETKIT'],
			leftConfig: [
				{type: 'input', label: '输入二级资源ID/名称', property: 'searchkey'},
				{type: 'select', label: '选择一级资源', property: 'header', items: []}
			],
			rightConfig: [
				// {label: '预设', icon: 'yushe', event: 'import'},
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			headers: [
				{text: '二级ID', value: 'code'},
				{text: '二级名称', value: 'description'},
				{text: '一级资源', value: 'header'},
				{text: '排序', value: 'seq'},
				{text: '状态', value: 'status'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "二级资源ID"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "二级资源名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'header',
						connector: {
							props: {
								required: true,
								label: "一级资源",
								items: [],
								someToValue: "desc"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						disabledDeleteDefault: true,
						property: 'status',
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					}
				]
			}
		}
	},
	methods: {
		beforeSave(form) {
			form.type = 'sourcetwo';
		},
		createdExtend() {
			this.fixedParams.rootKey = this.pageParams[0];
			this.fixedParams.type = 'sourcetwo';
		},
    beforeOpenAddDialog(v) {
      if (v === 'add' && this.$refs.ct?.$refs?.leftBtn?.form?.header) {
        this.listDeploy.row[2].default = this.$refs.ct.$refs.leftBtn.form.header
      }
    }
	},
	watch: {
		'$store.state.secondResource': {
			handler(v) {
				if (v === 0 || v) {
					this.getParentResourceMenu(this.pageParams[v], '');
					this.fixedParams.rootKey = this.pageParams[v];
					this.fixedParams.type = 'sourcetwo';
					this.form.header = '';
					this.form.searchkey = '';
					this.list();
				}
			}
		}
	},
	mounted() {
		this.getParentResourceMenu(this.pageParams[0], '');
	}
}
</script>

<style scoped lang="scss">

</style>

